@import '../node_modules/@splidejs/splide/dist/css/splide.min.css';
@import '../node_modules/@splidejs/splide/dist/css/themes/splide-default.min.css';
@import '../node_modules/photoswipe/dist/photoswipe.css';
@import '../node_modules/photoswipe/dist/default-skin/default-skin.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  border-top: 5px solid #bc7312;
}

#book-now {
  min-height: 100vh
}

#book-now iframe {
  height: calc(100vh - 130px)
}

